@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');


.WeatherApp {
  text-align: center;
  padding: 20px;
  font-family: 'Roboto Slab', serif;
  letter-spacing: 4px;
  color: white;
  position: relative;
  min-height: 100vh;
}


.WeatherApp::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('https://images.unsplash.com/photo-1587513418817-a84420e494ad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=627&q=80') center center / cover no-repeat;
  z-index: -2;
}

.search-container {
  position: relative;
  z-index: 1;
}

.search {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #ccc;
  position: relative;
  z-index: 1;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px 4px 4px 4px;
  margin-left: 20px;
  position: relative;
  z-index: 1;
}

.icon-button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  height: 100%;
}

.icon {
  width: 35px; 
  height: 100%;
  vertical-align: middle;
  margin-right: 5px;
  filter: invert(1);
}

.WeatherInfo {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.WeatherInfo h1 {
  font-size: 24px;
  margin-bottom: 10px;
}


h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

h3 {
  font-size: 18px;
  margin-bottom: 10px;
}



.WeatherInfo p {
  font-size: 16px;
  margin-bottom: 5px;
}

.weather-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  border-radius: 8px;
  margin-left: 150px;
  margin-right: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.CardForText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 50px;
  border-radius: 8px;
  background: #fcfbfc;
  color: #2497ea; 
}




@media screen and (max-width: 600px) {
  .weather-display {
    margin: 10px 20px;
  }
}
