@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.quiz-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.quiz-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.quiz-form .category-title,
.quiz-form .difficulty-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.quiz-form select {
  padding: 5px 10px;
  font-size: 16px;
  margin-bottom: 20px;
}

.quiz-form input[type="submit"] {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #ff6d80;
  color: #fff;
  border: none;
  cursor: pointer;
}

.quiz-score {
  display: flex;
  color: #2497ea;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.quiz-score div {
  font-size: 20px;
  margin-top: 100px;
  margin-bottom: 20px;
}

.quiz-score button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #2497ea;
  color: #fff;
  border: none;
  cursor: pointer;
}

.quiz-question {
  display: flex;
  color: #2497ea;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 100px;
  margin: 25px;
}

.quiz-question .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.quiz-question button {
    margin: 20px;
}

.quiz-question div {
  font-size: 20px;
  margin-top:20px;
}



.quiz-question .option-button:last-child {
  margin-bottom: 0;
  color: #2497ea;
}

.quiz-question .answer-feedback {
  font-size:18px;
  margin-bottom: 10px;
}


form {
  padding-left: 5px;
  padding: 5px;
  margin: 5px;
  margin: 0 auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
}
select {
    width: 80%;
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    color: #2497ea;
    font-family: 'Poppins', sans-serif;
    background-color: #f1f1f1;
    accent-color: #56b0d4;
    margin: 20px;
    padding: 20px;
  }

  .button-text {
    color: #ff6d80;
  }

  .next-question-button-text {
    color: #2497ea;
  }

  .card-for-quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 520px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 50px;
    border-radius: 8px;
    background: #fcfbfc;
    color: #2497ea; 
  
  }
 
  

@media screen and (max-width: 480px) {
    form {
        width: 90%;
    }
    select {
        width: 90%;
        display: flex;
    }
    .quiz-question button {
        margin: 10px;
    }
}